<template>
  <div
    class="px-0 container-fluid h-100"
    style="text-align: left"
  >
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['Payments']" />
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12 text-left">
              <div style="
                    font-size: 20px;
                    font-weight: 400;
                    color: var(--el-app-primary);
                    margin-bottom: 15px;">
                <strong>Payments</strong><br />
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div
              class=""
              style="display: flex"
            >
              <input
                type="text"
                @input="onSearch"
                v-model.trim="searchName"
                placeholder="Search Name"
                class="search_by_input"
                spellcheck="off"
              />

              <div style="margin-left: 20px">
                <el-select
                  v-model="selectedFilter"
                  @handleOptionClick="handleOptionClick"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="margin-left: 20px">
                <el-select
                  v-model="selectedPayMethodFilter"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in payMethods"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="margin-left: 20px">
                <el-select
                  v-model="statusFilter"
                  placeholder="Select"
                  class="d-block"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <hr />
          <div
            v-loading="(loading && payments.length > 0)"
            class="table-responsive"
          >
            <data-tables-server
              :data="payments"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              :total="totalPayments"
              :current-page.sync="currentPage"
              :page-size='pageSize'
              @query-change='onTableChange'
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getAllPayments"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Payments Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Payments</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{  ((currentPage -1 ) * pageSize) + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Internal Reference"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="150px"
              >
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <div style="margin-left: 10px">
                      <span>{{ scope.row.internal_ref }}</span><br />
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Name"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="190px"
              >
                <template slot-scope="scope">
                  <span>{{ `${scope.row.website_online_application.title} ${scope.row.website_online_application.first_name} ${scope.row.website_online_application.last_name}` }}</span><br />
                  <span>
                   TEL: {{ scope.row.website_online_application.phone_number || 'N/A' }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="Particulars">
                <template slot-scope="scope">
                  <span>{{ scope.row.narration }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Status"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="100px"
              >
                <template slot-scope="scope">
                  <div>
                    <el-tooltip
                      content="Successful"
                      placement="top"
                    >
                      <span
                        v-if="scope.row.status == 'SUCCESSFUL'"
                        style="color: green; font-weight: 500"
                      >
                        <i class="el-icon-check"></i>
                      </span>
                    </el-tooltip>
                    <el-tooltip
                      content="Failed"
                      placement="top"
                    >
                      <span
                        v-if="scope.row.status == 'FAILED'"
                        style="color: red; font-weight: 500"
                      >
                        <i class="el-icon-close"></i></span>
                    </el-tooltip>
                    <el-tooltip
                      content="Pending"
                      placement="top"
                    >
                      <span
                        v-if="scope.row.status == 'PENDING'"
                        style="color: orange; font-weight: 500"
                      >
                        <i class="el-icon-alarm-clock"></i></span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                label-class-name="text-center"
                class-name="text-center"
                style="display: none;"
                width="80px"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; justify-content: center">
                    <TransactionsDetailsDialog :payment-detail="scope.row" />
                  </el-row>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>

        </div>
      </section>
    </article>

    <ChooseTransactionRangeDialog
      :dialogVisible="ChooseTransactionRangeDialogVisible"
      @on-close="ChooseTransactionRangeDialogVisible = false"
      @on-range-chosen="onRangeChosen"
    />

  </div>
</template>
  
  <script>
import ChooseTransactionRangeDialog from "../components/dialogs/choose-transaction-range-dialog.vue";
import ScaleOut from "@/components/scale-out-component.vue";
import debounce from "debounce";
import TransactionsDetailsDialog from "../components/dialogs/transactions-detail-dialog.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import { format, parseISO } from "date-fns";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
    TransactionsDetailsDialog,
    ChooseTransactionRangeDialog,
  },

  data() {
    return {
      ChooseTransactionRangeDialogVisible: false,
      loading: true,
      loadingError: false,
      totalPayments: 0,
      currentPage: 0,
      pageSize: 10,
      payments: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      regNoSearch: "",
      studentNameSearch: "",
      searchName: "",

      selectedFilter: "Show All",

      selectedPayMethodFilter: "",
        payMethods: [
          {
            label: "Show All",
            value: "",
          },
          {
            label: "Card Payments",
            value: "CARD",
          },
          {
            label: "Mobile Money",
            value: "MOBILE_MONEY",
          },
        ],

      statusFilter: "",
        statusOptions: [
          {
            label: "Show All",
            value: "",
          },
          {
            label: "Only Only Pending",
            value: "PENDING",
          },
          {
            label: "Only Successful",
            value: "SUCCESSFUL",
          },
          {
            label: "Only Failed",
            value: "FAILED",
          },
        ],
      };
  },

  computed: {
    filterOptions() {
      let chosenLectureDateRange;
      if (!this.filterDateStart || !this.filterDateEnd) {
        chosenLectureDateRange = "Select a date Range";
      } else {
        chosenLectureDateRange = `${format(
          parseISO(this.filterDateStart),
          "do MMMM yyyy"
        )} - ${format(parseISO(this.filterDateEnd), "do MMMM yyyy")}`;
      }

      return [
        {
          label: "Show All",
          value: "",
        },
        {
          label: chosenLectureDateRange,
          value: "customDate",
        },
      ];
    },
  },

  watch: {
    selectedPayMethodFilter() {
      this.onSearch();
    },

    statusFilter() {
      this.onSearch();
    },

    selectedFilter() {
      if (
        this.selectedFilter === ""
      ) {
        this.filterDateStart = "";
        this.filterDateEnd = "";
        this.onSearch();
      }
    },
  },

  methods: {
    onRangeChosen(startAndEndTime) {
      this.ChooseTransactionRangeDialogVisible = false;
      this.filterDateStart = startAndEndTime[0];
      this.filterDateEnd = startAndEndTime[1];
      this.getAllPayments();
    },

    handleOptionClick() {
      //Ensure that the DOM has been updated before proceeding
      this.$nextTick(() => {
        //Only react if the option is for date
        if (
          this.selectedFilter != ""
        ) {
          this.ChooseTransactionRangeDialogVisible = true;
        }
      });
    },

    onSearch: debounce(function () {
      this.getAllPayments();
    }, 500),

    async onTableChange(queryInfo) {
      if (queryInfo) {
        if (queryInfo.type == "page" || queryInfo.type == "size") {
          this.currentPage = queryInfo.page;
          this.pageSize = queryInfo.pageSize;
          this.getAllPayments();
        }
      }
    },

    async getAllPayments() {
      try {
        this.loading = true;
        this.loadingError = false;

        //If the user has added a range filter, cater for it too
        let paymentsRange = "";
        if (this.filterDateStart && this.filterDateEnd) {
          paymentsRange = `&dateRange=${this.filterDateStart}_${this.filterDateEnd}`;
        }

        let request = await this.$http.get(
          `website/online-web-application-payments/fetch-many?page=${this.currentPage}&pageSize=${this.pageSize}&nameSearch=${this.searchName}&transactionStatus=${this.statusFilter}${paymentsRange}&paymentMethodFilter=${this.selectedPayMethodFilter}`
        );
        if (
          request.data.success &&
          request.data.message == "WEBSITE ONLINE PAYMENTS FETCHED SUCCESSFULLY"
        ) {
          this.payments = request.data.payments;
          this.totalPayments = request.data.totalCount;
          window.scrollTo(0, 0);
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to load Payments",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
  
  <style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 9px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>
  
<style>
.payments_dialogs .el-dialog {
  width: 60%;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .payments_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .payments_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .payments_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .payments_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
  