<template>
  <div>
    <article>
      <el-tooltip
        content="Details"
        placement="top"
      >
        <el-button
          type="primary"
          class="mr-1 ml-1"
          icon="el-icon-view"
          @click="showModal"
          circle
        >
        </el-button>
      </el-tooltip>
      <section>
        <div class="container">
          <div class="students_dialogs text-left">
            <el-dialog
              title="Payment Details"
              :visible.sync="transactionDetailsModal"
            >
              <div>
                <p>
                  <span style="font-weight: 700">Student Name:</span>
                  {{ `${paymentDetail.website_online_application.title} ${paymentDetail.website_online_application.first_name} ${paymentDetail.website_online_application.last_name}` }}
                </p>
                <p>
                  <span style="font-weight: 700">Internal Reference:</span>
                  {{ paymentDetail.internal_ref }}
                </p>
                <p>
                  <span style="font-weight: 700">Amount Paid:</span>
                  {{ `${paymentDetail.currency} ${paymentDetail.amount}` }}
                </p>
                <p>
                  <span style="font-weight: 700">Payment Method:</span>
                  {{ paymentDetail.payment_method }}
                </p>
                <p>
                  <span style="font-weight: 700">Payment Status:</span>
                  {{ paymentDetail.status }}
                </p>
                <p>
                  <span style="font-weight: 700">Particulars:</span>
                  {{ paymentDetail.narration }}
                </p>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>
<script>
export default {
  data() {
    return { transactionDetailsModal: false };
  },
  props: { paymentDetail: { type: Object, required: true } },
  methods: {
    showModal() {
      this.transactionDetailsModal = true;
    },
  },
};
</script>